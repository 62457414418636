import { Typography } from "@material-ui/core";
import { useState, useEffect } from "react";

interface Props {
  start: string;
}

const Clock = ({ start }: Props) => {
  const [date, setDate] = useState(new Date(start));

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return () => {
      clearInterval(timerID);
    };
  });

  const tick = () => {
    setDate(new Date());
  };

  return (
    <div>
      <Typography>{date.toLocaleTimeString()}</Typography>
    </div>
  );
};

export default Clock;
