import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import GuestPass from "./ui/GuestPass";

function App() {
  return (
    <Switch>
      <Route path={ROUTES.GUEST_PASS} component={GuestPass} />
    </Switch>
  );
}

export default App;
