import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import StatusBox from "./StatusBox";

export interface PassData {
  buildingLogo: string;
  createdAt: string;
  firstName: string;
  guestPassStatus: number;
  isExpired: boolean;
  lastName: string;
  unitNumber: string;
}

export enum Status {
  Pending = 0,
  Expired,
  Approved,
  Denied
}

const GuestPass = () => {
  const [data, setData] = useState<null | PassData>(null);
  const [status, setStatus] = useState("loading");

  const { guestId } = useParams<{ guestId: string }>();

  useEffect(() => {
    const doFetch = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/livly/guests/guestPass/${guestId}`
        );
        setData(data.data.Data);
        setStatus("ok");
      } catch (e) {
        setStatus("error");
      }
    };
    doFetch();
  }, [guestId]);

  if (status === "loading") {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100vw"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (data) {
    return (
      <Box>
        {data.buildingLogo ? (
          <img
            alt="Building Logo"
            style={{ width: "100vw" }}
            src={data.buildingLogo}
          />
        ) : (
          <Box
            width="100vw"
            bgcolor="#C8C8C8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="10em"
          >
            <Typography style={{ color: "white" }}>
              <strong>Building Logo Placeholder</strong>
            </Typography>
          </Box>
        )}

        <Box
          width="80vw"
          display="flex"
          flexDirection="column"
          alignItems="center"
          margin="0 auto"
        >
          <Box my={2} textAlign="center">
            <Typography variant="body1">
              <strong>
                Guest of {data.firstName} {data.lastName}
              </strong>
            </Typography>
            {data.unitNumber && <Typography>Unit {data.unitNumber}</Typography>}
          </Box>
          <StatusBox guestPass={data} />
        </Box>
      </Box>
    );
  }
  return <></>;
};

export default GuestPass;
