import { Box, Typography } from "@material-ui/core";
import React from "react";
import { PassData, Status } from "./GuestPass";
import Clock from "./Clock";

interface Props {
  guestPass: PassData;
}

const StatusBox = ({ guestPass }: Props) => {
  // console.log(data.)
  const getFormattedData = () => {
    switch (guestPass.guestPassStatus) {
      case Status.Approved:
        return {
          bgColor: "rgba(78,199,121)",
          statusTitle: "APPROVED",
          showClock: true
        };
      case Status.Denied:
        return {
          bgColor: "#FA4747",
          statusTitle: "DENIED",
          statusDescription:
            "Your request has been denied. Please contact the resident or property management."
        };
      case Status.Expired:
        return {
          bgColor: "#FA4747",
          statusTitle: "EXPIRED",
          statusDescription:
            "Your request has expired. Contact the resident to receive a new pass."
        };
      case Status.Pending:
        return {
          bgColor: "#E2E2E2",
          statusTitle: "PENDING",
          statusDescription:
            "Your request has been sent to the resident for approval."
        };
      default:
        return { bgColor: "#E2E2E2", statusTitle: "" };
    }
  };

  const {
    bgColor,
    statusTitle,
    statusDescription,
    showClock
  } = getFormattedData();

  return (
    <>
      <Box
        borderRadius="4px"
        width="100%"
        bgcolor={bgColor}
        py="18px"
        px="8px"
        textAlign="center"
        m="0 auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5">{statusTitle}</Typography>
        {showClock && <Clock start={guestPass.createdAt} />}
      </Box>
      {statusDescription && (
        <Box mt={2} textAlign="center">
          <Typography variant="subtitle1">{statusDescription}</Typography>
        </Box>
      )}
    </>
  );
};

export default StatusBox;
